// material ui
import { Button, IconButton, Modal } from "@mui/material"

// react icons
import { TfiClose } from "react-icons/tfi"

// images
import react from "../assets/images/logo.svg"
import nodejs from "../assets/images/nodejs.png"
import mongodb from "../assets/images/mongodb.png"
import webrtc from "../assets/images/webrtc.png"
import peerjs from "../assets/images/peerjs.png"
import stripe from "../assets/images/stripe.png"
import sendgrid from "../assets/images/sendgrid.png"
import nginx from "../assets/images/nginx.png"

// custom hook
import { useResponsiveMedia } from "../utils/helpers"

// constants
import { MAINCOLOR,  MEDICALANALYTICS, mobile } from "../utils/constants"

const msg1 = "The latest project:"
const msg2 = "Medical Teleconsultation"
const msg3 = "There are two parts to the application - the Patients' and the Doctors' portals"
const msg4 = "This application can be used in three ways:"
const lineitem1 = "As a standalone website for a clinic"
const lineitem2 = "Embedded through an API generated by the system, to allow a link from the clinic's existing website"
const lineitem3 = "Within an Uber-like matching system for Doctors to Patients through a centralised portal"
const msg5 = "The application was developed with these technologies:"

interface LogoProps {
	icon: string
	label?: string
	height?: string
}

interface ProjectsProps {
	open: boolean
	close: () => void
}

const Logo = ({ icon, label, height }: LogoProps) => {
	const _height = height || "40px"
	const media = useResponsiveMedia()

	return (
		<div className="logo">
			<img src={icon} alt={"logo"} style={{ height: mobile(media) ? "10px" : _height }} />
			{label && label}
		</div>
	)
}

const TextBox = () => {
	return (
		<>
			<div className="textbox headline">
				<div>{msg1}</div>
				<div className="textbox projectName">{msg2}</div>
			</div>
			<div style={{ marginTop: "20px" }}>{msg3}</div>
			<div style={{ marginTop: "20px", fontWeight: "bold" }}>{msg4}</div>
			<div style={{ marginBottom: "20px" }}>
				<ul>
					<li className="textbox lineitem">{lineitem1}</li>
					<li className="textbox lineitem">{lineitem2}</li>
					<li className="textbox lineitem">{lineitem3}</li>
				</ul>
			</div>
			<div style={{ marginBottom: "10px" }}>{msg5}</div>
		</>
	)
}

const Projects = ({ open, close }: ProjectsProps) => {

	const handleButtonClick = () => {
		window.open(MEDICALANALYTICS, "_blank", "noopener,noreferrer")
		close()
	}

	return (
		<Modal open={open}>
			<div className="projects">
				<div className="body">
					<div className="title">
						<div>Projects</div>
						<div>
							<IconButton onClick={close} size="small">
								<TfiClose style={{ color: "white" }} />
							</IconButton>
						</div>
					</div>
					<TextBox />
					<div className="column">
						<div className="grid">
							<Logo icon={react} label="ReactJs" />
							<Logo icon={nodejs} label="NodeJs/Express" />
							<Logo icon={mongodb} label="MongoDB/Mongoose" />
							<Logo icon={webrtc} label="WebRTC" />
							<Logo icon={peerjs} />

							<div className="textlogo">
								<div>COTURN</div>
								<div>turnserver</div>
							</div>
							<Logo icon={stripe} />
							<Logo icon={sendgrid} height="30px" />
							<Logo icon={nginx} height="30px" />
						</div>
						<div className="buttonContainer">
							<Button variant="contained" onClick={handleButtonClick} style={{ backgroundColor: MAINCOLOR }}>
								Go to project
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default Projects
